import Image from "next/image";
import React, { FC } from "react";
import { animated, useSprings } from "react-spring";
import styled from "styled-components";

import AbstractAgreementCardImageSVG from "~/assets/images/abstract-agreement-card.svg";
import AbstractDashboardActivityImageSVG from "~/assets/images/abstract-dashboard-activity.svg";
import AbstractNavImageSVG from "~/assets/images/abstract-navigation.svg";
import arrowImageUrl from "~/assets/images/curved-downward-right-arrow.png";
import backgroundImageUrl from "~/assets/images/denim-texture-background.png";
import starburstImageUrl from "~/assets/images/starburst.png";
import { ResponsiveHide } from "~/components/Hidden/ResponsiveHide";

const ArrowImage = styled((props) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  <animated.div {...props}>
    <Image src={arrowImageUrl} width="100" height="90" />
  </animated.div>
))``;

const StarburstImage = styled((props) => (
  <animated.div {...props}>
    <Image
      src={starburstImageUrl}
      width="115"
      height="115"
      style={{ transform: "rotate(45deg)" }}
    />
  </animated.div>
))``;

const AbstractNavImage = styled((props) => (
  <animated.div {...props}>
    <AbstractNavImageSVG width="246" height="580" />
  </animated.div>
))``;

const AbstractAgreementCardImage = styled((props) => (
  <animated.div {...props}>
    <AbstractAgreementCardImageSVG width="423" height="240" />
  </animated.div>
))``;

const AbstractDashboardActivityImage = styled((props) => (
  <animated.div {...props}>
    <AbstractDashboardActivityImageSVG width="450" height="90" />
  </animated.div>
))``;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const LoginBoxWrapper = styled(animated.div)`
  z-index: 1100;
  position: relative;
`;

export const AuthenticationWrapper: FC = ({ children }) => {
  const [
    [
      wrapperStyles,
      starburstStyles,
      arrowStyles,
      abstractNavStyles,
      abstractCardStyles,
      abstractActivityStyles,
    ],
    api,
  ] = useSprings(6, (i) => {
    return {
      from: { scale: 0.7, opacity: 0 },
      to: { scale: 1, opacity: 1 },
      delay: [400, 500, 600, 700, 750, 800][i],
      config: {
        mass: [0.6, 0.4, 0.3, 0.5, 0.3, 0.3][i],
        friction: 10,
        tension: 200,
        clamp: false,
      },
    };
  });
  api.start();

  return (
    <AuthenticationContainer>
      <div
        css={`
          z-index: 1000;
          position: relative;
        `}
      >
        <StarburstImage
          style={starburstStyles}
          css={`
            position: absolute;

            right: -3.5rem;
            top: -3.5rem;
          `}
        />
        <LoginBoxWrapper style={wrapperStyles}>{children}</LoginBoxWrapper>
        <ResponsiveHide below="sm">
          <ArrowImage
            style={arrowStyles}
            css={`
              position: absolute;

              left: -6rem;
              bottom: 3.5rem;

              z-index: 1000;
            `}
          />
          <AbstractNavImage
            style={abstractNavStyles}
            css={`
              position: absolute;

              left: -9rem;
              top: -9rem;
            `}
          />
          <AbstractAgreementCardImage
            style={abstractCardStyles}
            css={`
              position: absolute;

              right: -20rem;
              bottom: 1rem;
            `}
          />
          <AbstractDashboardActivityImage
            style={abstractActivityStyles}
            css={`
              position: absolute;

              left: -9rem;
              bottom: -5rem;
            `}
          />
        </ResponsiveHide>
      </div>
    </AuthenticationContainer>
  );
};

const AuthenticationContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.navy};
  background-image: url("${backgroundImageUrl.src}");
  display: flex;
  min-height: 100vh;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
